import { render, staticRenderFns } from "./mianzheng-add.vue?vue&type=template&id=5018d608&scoped=true&"
import script from "./mianzheng-add.vue?vue&type=script&lang=js&"
export * from "./mianzheng-add.vue?vue&type=script&lang=js&"
import style0 from "./mianzheng-add.vue?vue&type=style&index=0&lang=css&"
import style1 from "./mianzheng-add.vue?vue&type=style&index=1&id=5018d608&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5018d608",
  null
  
)

export default component.exports